<template>
  <div class="flex flex-row justify-between items-start">
    <div class="w-auto">
      <slot></slot>
    </div>
    <button class="btn info" @click="open = !open">
      <i class="fas" :class="{ 'fa-caret-down': !open, 'fa-caret-up': open }"></i>
    </button>
  </div>
</template>

<script setup>
import { defineModel } from 'vue'

defineProps({
  defaultOpen: {
    type: Boolean,
    default: false,
  },
})

const open = defineModel('open', { type: Boolean, default: props => !!props.defaultOpen })
</script>
